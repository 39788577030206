import React, { useEffect, useState } from "react";
import "../../css/HomePage.css";
import Footer from "../../components/Footer";
import logo from "../../assets/Logo.png";
import contentLogo from "../../assets/bottts-removebg.png";
import npm from "../../assets/npm.png";
import boxDiv from "../config/boxDiv";
import Copyright from "./Copyright";
import "../../jsHandler/boxCssHandler";
import { Link } from "react-router-dom";
function HomePage() {
  const [inputValue, setInputValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [wordList, setWordList] = useState([]);
  const [contentList, setList] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [filterResult, setFilterResult] = useState("FALSE");
  const [falsePositive, setFalsePositive] = useState("FALSE");
  // const [index1, setIndex1] = useState("");
  // const [index2, setIndex2] = useState("");
  let tempSum = 0,
    index1,
    index2,
    tempString;
  console.log(contentList);

  const handleSubmit = () => {
    // sum = 0;
    const isValid = inputValue === "" ? false : true;
    if (isValid) {
      tempSum = 0;
      tempString = inputValue.trim();
      for (let i = 0; i < tempString.length; i++) {
        let code = tempString.charCodeAt(i);
        tempSum += code;
      }

      // setIndex1((tempSum + 857) % 10);
      // setIndex2((tempSum + 521) % 10);
      index1 = (tempSum + 857) % 10;
      index2 = (tempSum + 521) % 10;
      console.log("TempSum = ", tempSum);
      console.log("Index 1 = ", index1, " Index 2 = ", index2);

      setList((existingItems) => {
        return [
          ...existingItems.slice(0, index1),
          1,
          ...existingItems.slice(index1 + 1),
        ];
      });

      setList((existingItems) => {
        return [
          ...existingItems.slice(0, index2),
          1,
          ...existingItems.slice(index2 + 1),
        ];
      });
      setWordList([...wordList, inputValue]);
      setInputValue("");
    }
  };
  const searchList = () => {
    const isValid = searchValue === "" ? false : true;
    if (isValid) {
      tempSum = 0;
      tempString = searchValue.trim();
      for (let i = 0; i < tempString.length; i++) {
        let code = tempString.charCodeAt(i);
        tempSum += code;
      }
    }
    index1 = (tempSum + 857) % 10;
    index2 = (tempSum + 521) % 10;
    console.log("Index 1 = ", index1, " Index 2 = ", index2);
    if (contentList[index1] == 1 && contentList[index2] == 1) {
      console.log("Index 1 = ", index1, " Index 2 = ", index2);
      setFilterResult("TRUE");
    } else {
      setFilterResult("FALSE");
    }
    if (wordList.includes(searchValue)) setFalsePositive("FALSE");
    else setFalsePositive("TRUE");
  };
  useEffect(() => {
    contentList.map((val, index) => {
      if (val == 1) {
        document.getElementById(index).classList.remove("statusNeutral");
        document.getElementById(index).classList.add("statusGreen");
      } else if (val == 0) {
        document.getElementById(index).classList.remove("statusGreen");
        document.getElementById(index).classList.add("statusNeutral");
      }
    });
  }, [wordList]);
  const handleClear = () => {
    setWordList([]);
    localStorage.removeItem("wordList");
    setList([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  };

  useEffect(() => {
    const data = localStorage.getItem("wordList");
    if (data !== null) {
      setWordList(JSON.parse(data));
      console.log(wordList);
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("wordList", JSON.stringify(wordList));
  }, [wordList]);
  useEffect(() => {
    const data = localStorage.getItem("contentList");
    if (data !== null) {
      setList(JSON.parse(data));
    }
  }, []);
  useEffect(() => {
    localStorage.setItem("contentList", JSON.stringify(contentList));
  }, [contentList]);

  return (
    <div className="HomeMainContainer">
      <div className="HomeSubContainer">
        <div className="HeaderMainContainer">
          <div className="npmPackage">
            <a href="https://www.npmjs.com/package/blumea">
              <img src={npm} alt=""></img>&nbsp;&nbsp; Get started now!
            </a>
          </div>
          <div className="HeaderContainer">
            <div className="navigationBar">
              <div className="brandSection">
                <img src={logo} alt=""></img>&nbsp;&nbsp;
                <span>Blumea</span>
              </div>
              <div className="linkSection">
                {/* <a>Services</a> */}
                <a href="https://docs-blumea.vercel.app/">Documentation</a>
                {/* <a>NPM Package</a> */}
                {/* <a>Contact</a> */}
              </div>
            </div>
            <div className="HeaderContent">
              <div className="textSection">
                <div className="textHeading">
                  Blumify yours projects with Blumea
                </div>
                <div className="textContent">
                  A fast and effiecient package to implement bloom-filter in
                  your projects and prevent potential clashes in usernames.
                </div>
                {/* <div className="buttonSection">
                  <button className="button-19 buttonAnimation">
                    See How it Works!
                  </button>
                </div> */}
              </div>
              <div className="imageSection">
                <img src={contentLogo} alt=""></img>
              </div>
            </div>
          </div>
        </div>
        <div className="aboutSection">
          <div className="aboutSectionSubContainer">
            <div className="leftSection">
              <div className="textHeading">All about Bloom Filter</div>
              <div className="textContent">
                A Bloom filter is a space-efficient probabilistic data structure
                that is used to test whether an element is a member of a set.
                For example, checking availability of username is set membership
                problem, where the set is the list of all registered username.
              </div>
            </div>
            <div className="rightSection">
              <div className="textHeading">Want to know more about it?</div>
              <div className="textContent">
                <div className="buttonSection">
                  <button className="button-20 buttonAnimation">
                    <a href="https://docs-blumea.vercel.app/">Read More</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="testSection1">
          <div className="overLayText1">Add strings to the bloom filter</div>
          <div className="summaryText">
            The base data structure of a Bloom filter is a Bit Vector. Here's a
            small one we'll use to demonstrate:
          </div>
          <div className="testSectionContent">
            <div className="leftSection">
              {/* <div className="testHeading">Lets try it out !</div>
              <div className="subText">
                The base data structure of a Bloom filter is a Bit Vector.
                Here's a small one we'll use to demonstrate:
              </div> */}
              <div className="bloomContent">
                {/* <div className="indexGenerate">Generated Indexes : []</div> */}
                <div className="setContent">
                  Your Set : [
                  {wordList.map((val) => (
                    <span>{val},</span>
                  ))}{" "}
                  ]
                </div>
              </div>
            </div>
            <div className="rightSection">
              <div className="boxDiv">
                {contentList.map((val, index) => {
                  return (
                    <div>
                      <div className="boxDivUnit" id={index}>
                        {val}
                      </div>
                      <div className="boxIndexUnit">{index}</div>
                    </div>
                  );
                })}
              </div>
              <div className="fieldInput">
                <input
                  placeholder="Enter a username..."
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                ></input>
              </div>
              <div className="buttonSection">
                <button
                  onClick={handleSubmit}
                  className="button-19 buttonAnimation"
                >
                  Add to bloom filter
                </button>
                <button
                  onClick={handleClear}
                  className="button-19 buttonAnimation"
                >
                  Clear
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="testSection2">
          <div className="overLayText2">Check for presence</div>
          <div className="summaryText">
            To test for membership, you simply hash the string with the same
            hash functions, then see if those values are set in the bit vector.
            If they aren't, you know that the element isn't in the set. If they
            are, you only know that it might be, because another element or some
            combination of other elements could have set the same bits.
          </div>
          <div className="testSectionContent">
            <div className="leftSection">
              {/* <div className="boxDiv">
                {boxDiv.map((val, index) => {
                  return (
                    <div>
                      <div className="boxDivUnit">0</div>
                      <div className="boxIndexUnit">{index}</div>
                    </div>
                  );
                })}
              </div> */}
              <div className="fieldInput">
                <input
                  placeholder="Enter a username..."
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                ></input>
              </div>
              <div className="buttonSection">
                <button
                  className="button-19 buttonAnimation"
                  onClick={searchList}
                >
                  Test an element
                </button>
              </div>
            </div>
            <div className="rightSection">
              <div className="testHeding">Search For Presence</div>
              <div className="subText">
                Bloom Filter Result : {filterResult}
                <br></br>
                False Positive : {falsePositive}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Copyright />
      </div>
    </div>
  );
}

export default HomePage;
