import React from "react";
import "../css/NavBar.css";
import logo from "../assets/logo1.png";
function NavBar() {
  return (
    <div className="mainContainer">
      <div className="subContainer">
        <div className="brandSection">
          <div className="brandLogo">
            <img src={logo} alt="" width={"50px"}></img>
          </div>
          <div className="brandName">Blumea</div>
        </div>
              <div className="linkSection">
                  <a>Documentation</a>
                  <a>About Blumea</a>
                  <a>Support</a>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
