import React from "react";
import NavBar from "./NavBar";
import "../css/Docs.css";
function Docs() {
  return (
    <>
      <div className="docMainContainer">
        <NavBar />
        <div className="docSubContainer">
          <h1> Blumea Filter</h1>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate,
            dolorem ducimus, aliquid eius laboriosam doloribus vero ad
            perferendis saepe vel, molestiae eligendi. Deleniti, molestiae
            facere obcaecati doloribus minus aut voluptates!
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate,
            dolorem ducimus, aliquid eius laboriosam doloribus vero ad
            perferendis saepe vel, molestiae eligendi. Deleniti, molestiae
            facere obcaecati doloribus minus aut voluptates!
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate,
            dolorem ducimus, aliquid eius laboriosam doloribus vero ad
            perferendis saepe vel, molestiae eligendi. Deleniti, molestiae
            facere obcaecati doloribus minus aut voluptates!
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Cupiditate,
            dolorem ducimus, aliquid eius laboriosam doloribus vero ad
            perferendis saepe vel, molestiae eligendi. Deleniti, molestiae
            facere obcaecati doloribus minus aut voluptates!
          </p>
        </div>
      </div>
    </>
  );
}

export default Docs;
