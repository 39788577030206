import React from "react";
import "../css/Footer.css";
import logo from "../assets/Logo.png";
import { FaGithub, FaFacebook, FaTwitter, FaEnvelope } from "react-icons/fa";
import { BsArrowRight } from "react-icons/bs";
function Footer() {
  return (
    <div className="footerMainContainer">
      <div className="footerSubContainer">
        <div className="upperSection">
          <div className="footerBrandSection">
            <div className="heading">Blumea</div>
            <div className="tagLine">Your own bloom filter</div>
            <div className="subText">
              Enrich your project ideas with Blumea.
            </div>
          </div>
          <div className="contactDetails">
            <div>
              <FaEnvelope />
              &nbsp;blumea.support@gmail.com
            </div>
          </div>
          <div className="buttonDirect">
            <button className="footerButton buttonAnimation">
              <a href="https://www.npmjs.com/package/blumea">
                {" "}
                Start Using Blumea&nbsp;
              </a>

              <BsArrowRight />
            </button>
          </div>
        </div>
        <div className="lowerSection">
          <div className="socialHandles">
            <FaGithub className="socialIcon" />
            <FaFacebook className="socialIcon" />
            <FaTwitter className="socialIcon" />
          </div>
          <div className="pageLinks">
            {/* <a>NPM Package</a>
            <a>Documentation</a> */}
          </div>
          <div className="brandLogo">
            <img src={logo} alt="" width="80px" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
