import React from "react";
import { BiCopyright } from "react-icons/bi";
import "../../css/Copyright.css";
function Copyright() {
  return (
    <div className="copyright">
      <BiCopyright />
      &nbsp;Copyright 2022 All Rights Reserved
    </div>
  );
}

export default Copyright;
